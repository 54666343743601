export const APP_CONSTANTS = {
  EMAIL: 'support@mysticinsight.online',
  ADDRESS: '30 N Gould st Ste 4118, Sheridan, WY 82801, USA +8772370608',
  APP_NAME: 'Milky Way Entertainment LLC',
  APP_SHORT_NAME: 'Milky Way',
  WEBSITE_NAME: 'Mystic insights',
  WEBSITE: 'https://milkywayapps.com/'
} as const;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PLANS = [
  {
    name: '1-Month plan',
    description:
      'You will be billed USD $1 today for a 7-day trial. Once the trial ends, you will be billed USD $10 each month thereafter until you cancel',
    trialPeriod: '7-Day Trial',
    trialUnit: 'day',
    trialLenght: 7,
    fullPrice: 10,
    intervalLength: 1,
    intervalUnit: 'month',
    paypalPlanId: 'P-2NB26819AB6389638MYFISBY',
    trialSum: 1,
    planId: 'g_lpr_1month',
  },
  {
    name: '6-Month plan',
    description:
      'You will be billed USD $1 today for a 7-day trial. Once the trial ends, you will be billed USD $25 each 6 months thereafter until you cancel',
    trialPeriod: '7-Day Trial',
    trialUnit: 'day',
    trialLenght: 7,
    fullPrice: 25,
    intervalLength: 6,
    intervalUnit: 'months',
    paypalPlanId: 'P-1HP72971TV704141FMYFISVQ',
    trialSum: 1,
    planId: 'g_lpr_6month',
  },
  {
    name: '12-Month plan',
    description:
      'You will be billed USD $1 today for a 7-day trial. Once the trial ends, you will be billed USD $50 each 12 months thereafter until you cancel',
    trialPeriod: '7-Day Trial',
    trialUnit: 'day',
    trialLenght: 7,
    fullPrice: 50,
    intervalLength: 12,
    intervalUnit: 'months',
    paypalPlanId: 'P-34977003P4507531FMYFITII',
    trialSum: 1,
    planId: 'g_lpr_12month',
  },
];
