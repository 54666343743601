import React from 'react';
import { Box, Checkbox, Link, Typography } from '@mui/material';

import mob_intro_bottom from '../../../assets/images/quiz/mob_intro_bottom.webp';
import { Routes } from '../../../App';
import { APP_CONSTANTS } from '../../../utils/constants';
import { useSelectedPlan } from '../hooks';
import SubscriptionTerms from './SubscriptionTerms';
import checkbox from '../../../assets/svg/checkbox.svg';
import { useQuizState } from '../../../providers/QuizProvider.hooks';

function Footer() {
  const { selectedPlan, trialEnd, montlyPrice } = useSelectedPlan();
  const { quizData, setQuizData } = useQuizState();

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        backgroundImage: `url(${mob_intro_bottom})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Box
        sx={{
          maxWidth: '460px',
          margin: 'auto',
          padding: '0 20px 200px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              flexWrap: 'wrap',
              '& .link': {
                fontFamily: 'Sans400',
                fontSize: '16px',
                color: '#fff',
                textDecoration: 'underline',
                transition: 'all 0.3s ease',
                whiteSpace: 'nowrap',
                '&:hover': {
                  opacity: 0.8,
                },
              },
            }}
          >
            <SubscriptionTerms montlyPrice={montlyPrice} trialEnd={trialEnd} selectedPlan={selectedPlan} />

            <Link target="_blank" href={Routes.PRIVACY} className="link">
              Privacy Policy
            </Link>
            <Link target="_blank" href={Routes.TERMS} className="link">
              Terms of Use
            </Link>
            <Link target="_blank" href={Routes.BILLING_TERMS} className="link">
              Billing Terms
            </Link>
            <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
              Contact Support
            </Link>

            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                margin: '16px 0',
                userSelect: 'none',
              }}
              onClick={() => setQuizData((prev) => ({ ...prev, isAlertMessagesAgreed: !prev.isAlertMessagesAgreed }))}
            >
              <Box
                sx={{
                  maxWidth: '36px',
                  width: '100%',
                  height: '36px',
                  borderRadius: '12px',
                  border: '1px solid #30304B',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Checkbox
                  id="alertMessagesAgreedCheckbox"
                  checked={quizData.isAlertMessagesAgreed}
                  value={quizData.isAlertMessagesAgreed}
                  checkedIcon={<img src={checkbox} alt="checkbox"></img>}
                  sx={{
                    color: '#E1413E',
                  }}
                ></Checkbox>
              </Box>
              <Typography
                sx={{
                  color: '#717889',
                  fontFamily: 'Sans400',
                  fontSize: '18px',
                  textTransform: 'uppercase',
                }}
              >
                I opt-in in sending and receiving account alert text messages.
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: 'Sans400',
                fontSize: '16px',
                color: '#717889',
                textAlign: 'center',
                margin: '8px 0',
                maxWidth: '220px',
              }}
            >
              <a href={APP_CONSTANTS.WEBSITE} className="link" target="_blank" rel="noreferrer">
                milkywayapps.com
              </a>
              <br />
              {APP_CONSTANTS.ADDRESS}
            </Typography>
            <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
              {APP_CONSTANTS.EMAIL}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
