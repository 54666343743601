import React from 'react';
import { QuizProvider } from './providers/QuizProvider';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';

import Main from './components/Main';
import Quiz from './components/Quiz';
import Subscription from './components/Subscription';
import Terms from './components/Docs/Terms';
import BillingTerms from './components/Docs/BillingTerms';
import Privacy from './components/Docs/Privacy';

export enum Routes {
  MAIN = '/mi',
  QUIZ = '/mi/quiz',
  SUBSCRIPTION = '/mi/subscription',
  PRIVACY = '/mi/privacy',
  TERMS = '/mi/terms',
  BILLING_TERMS = '/mi/billing-terms',
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <Main></Main>,
  },
  {
    path: Routes.QUIZ,
    element: <Quiz></Quiz>,
  },
  {
    path: Routes.SUBSCRIPTION,
    element: <Subscription></Subscription>,
  },
  {
    path: Routes.PRIVACY,
    element: <Privacy></Privacy>,
  },
  {
    path: Routes.TERMS,
    element: <Terms></Terms>,
  },
  {
    path: Routes.BILLING_TERMS,
    element: <BillingTerms></BillingTerms>,
  },
]);

function App() {
  return (
    <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY || ''}>
      <Elements>
        {/* <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
            vault: true,
            currency: 'USD',
            intent: 'subscription',
          }}
        > */}
        <QuizProvider>
          <RouterProvider router={router} />
        </QuizProvider>
        {/* </PayPalScriptProvider> */}
      </Elements>
    </RecurlyProvider>
  );
}

export default App;
