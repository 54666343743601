import { APP_CONSTANTS, PLANS } from '../../../utils/constants';
import { Typography } from '@mui/material';
import React from 'react';

interface ISubscriptionTermsProps {
  selectedPlan?: (typeof PLANS)[number];
  trialEnd?: string;
  montlyPrice?: string;
}

function SubscriptionTerms({
  selectedPlan,
  trialEnd,
  montlyPrice,
}: ISubscriptionTermsProps) {
  return (
    <Typography
      sx={{
        fontFamily: 'Sans400',
        fontSize: '16px',
        color: '#717889',
        textAlign: 'center',
        margin: '8px 0',
        '& .link': {
          fontFamily: 'Sans400',
          fontSize: '16px',
          color: '#fff',
          textDecoration: 'underline',
          transition: 'all 0.3s ease',
          whiteSpace: 'nowrap',
          '&:hover': {
            opacity: 0.8,
          },
        },
      }}
    >
      *Your {selectedPlan?.trialPeriod} will last until ({trialEnd}). You can cancel anytime before then and will not be
      charged the full program amount.
      <br />
      <br /> No question asked, no small print. If you decide{' '}
      <a href={APP_CONSTANTS.WEBSITE} className="link" target="_blank" rel="noreferrer">
        milkywayapps.com
      </a>{' '}
      is good for you, on ({trialEnd}) you will be charged one payment for ${selectedPlan?.fullPrice} for your{' '}
      {selectedPlan?.name} (${montlyPrice}/{selectedPlan?.intervalUnit}).
      <br />
      <br />
      <a href={APP_CONSTANTS.WEBSITE} className="link" target="_blank" rel="noreferrer">
        milkywayapps.com
      </a> will automatically charge your card ${selectedPlan?.fullPrice} every{' '}
      {selectedPlan?.intervalLength} {selectedPlan?.intervalUnit} so you don’t lose access to your account. No refunds
      or credits except if you don’t see results and follow our money-back policy. To cancel, simply let us know by
      email: {APP_CONSTANTS.EMAIL}
    </Typography>
  );
}

export default SubscriptionTerms;
